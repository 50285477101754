
import TableList from "./TableList.vue";
import Buttons from "../../Commons/Buttons.vue";
import SnackBar from "../../Commons/SnackBar.vue";
import Vue from "vue";
import { isEmpty, isNull, isUndefined } from "lodash";
import omit from 'lodash/omit';
import ParamService from "../../../../services/params-service";
import { CreativeFilters } from "../../../../interfaces/creative";
import { Paginated, SortingOption } from "../../../../interfaces/paginated";
import { mapGetters, mapActions } from "vuex";
import {
	prepareTableData,
	prepareSelectedData,
	prepareTableContent,
	preparedColumnDefsGrid
} from "../../../../utils/CustomizeViewData";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import CloneEntity from "../../Commons/DuplicatedEntity.vue";
// @ts-ignore
import { MessageTypes } from "@/interfaces/proccess";
// @ts-ignore
import ActivityLog from "../../Commons/ActivityLog.vue";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue"
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue"
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue"
// @ts-ignore
import ThumbnailGrid from "@/components/Header/Tables/ThumbnailGrid.vue"
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import ConfirmDelete from "@/views/Admin/Commons/ConfirmDelete.vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
//@ts-ignore
import { getPermisionCreatives } from "@/utils/permissionResolve";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "CreativesList",
	props: {},
	components: { 
		TableList, 
		Buttons, 
		SnackBar, 
		Alertize, 
		Dialog, 
		CloneEntity,  
		ActivityLog,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
		ThumbnailGrid,
		ConfirmDelete,
		NotPermission
	},
	data: () => ({
		title: "Creative List",
		paginated: { page: 1, limit: 25 } as Paginated,
		filters: {},
		options: getDefaultSortingOption(),
		table: {
			headers: [],
			items: []
		},
		isClone: false,
		fields: {},
		entityDuplicated: undefined,
		optionsDuplicated: {},
		openDuplicated: false,
		columnRestored: false,
		context: null,
		selectionRows: "single",
		optionDelete:{
			id:null,
			open:false,
			err: false,
			entity: "Creative",
			msg: "",
			sub_msg:""
		}
	}),
	created() {
		this.$nextTick(async () => {});
	},
	async mounted() {
		if (this.hasLineItemID) {
			this.filters.line_item_id = parseInt(this.getLineItemID);
			await this.resetFilter();
			await this.saveFilters();
		}
		if (this.hasAdvertiserID) {
				this.filters.advertiser_id = String(this.getAdvertiserID);
				await this.saveFilters();
			}
		await this.verifyFilters();
		await this.getPaginated();
		this.context = { componentParent: this };
	},
	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView"
		]),
		...mapGetters("profile", ["isRolReport", "getAbility"]),
		...mapGetters("customizer_filter", ["getFiltersEntity"]),
		...mapGetters("breadcrumbsM", ["getNavegationData"]),
		...mapGetters("internationalization", ["getLanguage"]),

		isReadOnly(){
			return this.isRolReport;
		},
		hasAdvertiserID() {
			return !!this.$route.query.advertiser_id;
		},
		hasLineItemID() {
			return this.$route.query.line_item_id > 0;
		},
		getLineItemID() {
			return this.$route.query.line_item_id;
		},
		getAdvertiserID() {
			return this.$route.query.advertiser_id;
		},
		getResultPaginate(): any {
			return this.$store.state.creative.result_paginate;
		},
		getData(): any[] {
			const result: any = this.getResultPaginate;
			return this.hasData(result) ? result.data : [];
		},
		getSizeCreativo() {
			return this.$t("show.creatives", {
				total: this.getData.length,
			});
		},

		getConfig() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields
			};
		},
		getConfigColumnDef(){
			return {
				context: this.context,
				eventActive: true,
				appNexus: false,
				entityName: "Creative",
				redirect: "CreativeEdit",
				activityLog: "ActivityLogCreative",
				edit: true,
				duplicated: this.isReadOnly ? false: true,
				log: this.isReadOnly ? false: true,
				readonly: this.isReadOnly,
				deleteAction:true,
				minWidthActions: 170,
				maxWidthActions: 170,
				permission: this.getPermission
			};
		},
		getFiltrosAplicados(){
			const newFilters = this.getFiltersEntity("CreativeFilter").filters;
			return Object.keys(newFilters).length > 0 ? newFilters : {};
		},
		getOptionsAplicados(){
			return this.getFiltersEntity("CreativeFilter").options;
		},

		getColumnsDef(){
			return preparedColumnDefsGrid(this.table.headers, this.getConfigColumnDef);
        },

        gerRowsData(){
            if(isEmpty(this.table.items)){
                return [];
            }else{
				return this.table.items;
			}
        },

		getTo(){
			return this.getNavegationData("to");
		},
		getFrom(){
			return this.getNavegationData("from");
		},

		canList(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionCreatives();
		}
	},
	async destroyed(){
		await this.resetFilter();
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		/** Begin Customize View **/

		...mapActions("proccess", ["setNotification"]),

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected",
		]),

		...mapActions("customizer_filter", [
			"saveFiltersEntity",
			"resetFilterEntity"
		]),

		...mapActions("clone_entity", ["fetchCloneEntity"]),

		...mapActions("creative", ["setActive", "deleteCreativeByID"]),

		openDialogDuplicated(params: any){
			this.entityDuplicated = params.id;
			this.getOptionsDuplicated(params.id);
			this.openDuplicated = true;
		},

		closeDialogDuplicated(){
			this.entityDuplicated = undefined;
			this.openDuplicated = false;
		},

		getOptionsDuplicated(id: number){
			this.optionsDuplicated = this.getData.find(c => c.id == id).clonable;
		},

		getMsgError(redirec: boolean, to: string, message: string) {
			let msgSucces = {
				title: "Error",
                message: message,
				type: MessageTypes.ERROR,
				show: true,
                details: "",
                to: redirec ? to: undefined,
				btn_text: "Close"
			};
			return msgSucces;
		},

		async handleDuplicated(params: any){
			this.isClone = true;
			await this.fetchCloneEntity({key: "creative", data: params})
			.catch((err) => {
				this.isClone = false;
				this.closeDialogDuplicated();
				this.setNotification(this.getMsgError(false, "", err.message));
			});
			this.isClone = false;
			this.closeDialogDuplicated();
			await this.updateParams({
				filters: this.filters,
				options: this.options
			});
		},

		async verifyFilters() {
			const newFilters = this.getFiltrosAplicados;
			if (Object.keys(newFilters).length < 1) return;
			this.filters = newFilters;
			this.options = this.getOptionsAplicados;
		},

		async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach(element => {
				let col = this.table.headers.filter(c => c.value == element.col);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async updateItemsTable(){
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);

			if(!isEmpty(selected)){
				this.fields = await prepareSelectedData(selected);

				this.table.items = await prepareTableContent(this.fields, this.getData);
				this.$forceUpdate();
			}
			
		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getData,
			});

			this.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "creative",
				entities: this.getData,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		/** End Customize View **/
		
		hasData(result: { data: any }): Boolean {
			return (
				!isUndefined(result) &&
				!isNull(result) &&
				!isUndefined(result.data) &&
				!isNull(result.data)
			);
		},
		async getPaginated(uTable: boolean = true) {
			if(this.canList){
				await this.setLoadingData(TypeLoading.loading);
				await this.$store.dispatch(
					"creative/paginated",
					await ParamService.getParams(
						this.paginated,
						this.filters,
						this.options
					)
				);
				if(uTable){
					await this.getTableData({ view: this.$route?.name });
				}else{
					await this.updateItemsTable();
				}
				await this.setLoadingData();
			}
		},
		updatePaginate(data: any) {
			this.paginated.page = data;
		},
		async setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] = !isUndefined(params.value)
				? params.value
				: "";
			await this.saveFilters();
		},
		async selectedOption(params: { options: SortingOption; filter: any }) {
			await this.setFilter({
				key: params.options.sort,
				value: params.filter
			});
			this.updatePaginate(1);
			await this.updateParams({
				filters: this.filters,
				options: params.options
			}, false);
		},
		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async updateParams(params: {
			filters: CreativeFilters;
			options: SortingOption;
		}, uTable: boolean = true) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated(uTable);
		},
		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption();
			await this.resetFilter();
			this.updatePaginate(1);
			await this.getPaginated(false);
		},
		async saveFilters(){
			this.saveFiltersEntity({view: "CreativeFilter", filters: { filters: this.filters, options: this.options}});
		},
		async resetFilter(){
			this.resetFilterEntity({view: "CreativeFilter", filters: { filters: {}, options: {}}});
		},
		async removeKeyFilter(key: string) {
			const newFilters: any = omit(this.filters, [key]);
			await this.updateParams({
				filters: newFilters,
				options: this.options,
			}, false);
			await this.saveFilters();
		},
		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},

		async handleActive(event: any) {
			await this.setLoadingData(event.active ? TypeLoading.enable : TypeLoading.disable);
			console.log({EVENTO: event});

			const reponse = await this.setActive({
				id: event.id,
				active: event.active,
			}).catch(async (error: any) => {
				this.setUpdateLineItem(event.id, true, !event.active, event.rowIndex); 
				await this.setLoadingData();
			});
			this.setUpdateLineItem(event.id, false, reponse, null); 

			await this.setLoadingData();
		},

		setUpdateLineItem(id: number, err: boolean, entity: any, rowIndex: any) {
			if(err){
				this.$refs.Grid_Creative.updateRowNodeByIndex(rowIndex, entity);
			}else{
				this.table.items.find((v: { id: number }) => v.id == id).active = entity.active;
			}
		},
		async updateIndex(){
			if (this.hasAdvertiserID) {
				this.filters.advertiser_id = this.getAdvertiserID;
				await this.saveFilters();
				this.getPaginated(false);
			}else{
				await this.removeKeyFilter("advertiser_id");
			}
		},

		async handlerCancelDelete(){
			this.optionDelete.open = false;
			this.optionDelete.id = null;
		},

		async handlerDeleteCreativeByID(id: Number){
			await this.setLoadingData(TypeLoading.loading);
			await this.deleteCreativeByID(id)
			.then(async (result) => {
				await this.handlerCancelDelete();
				await this.setLoadingData();
				await this.getPaginated(false);
			}).catch(async (err) => {
				await this.setLoadingData();
				await this.handlerCancelDelete();
			});
		},

		async deleteByID(params: any){
			this.optionDelete.open = true;
			this.optionDelete.id = params.id;
			this.optionDelete.err = false;

			let msg = `${i18n.t("delete_msg.creative.msg_type_one")} ${params.name} (ID: ${params.ext_id})?`

			this.optionDelete.msg = msg;
			this.optionDelete.sub_msg = i18n.t("delete_msg.creative.sub_msg");

			/*
			let creativeData = this.getData.find(c => c.id == params.id);
			if(!isNull(creativeData)){
				if(creativeData.line_associations_count > 0){
					this.optionDelete.err = true;
					this.optionDelete.sub_msg = "";
					this.optionDelete.msg = i18n.t("delete_msg.creative.msg_type_two");
				}
			}*/

		}
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated(false);
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
		"getTo"(val, old){
			this.updateIndex();
		},
	}
});
